.pageheader{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
}

.headerwrapper{
    width: 80%;
    color: black;
    text-decoration: none;
}

.image{
    width: 150px;
    height: auto;
}

.headline{
    font-size: 36px;
}

.headerwrapper{
    color: black;
    text-decoration: none;
    width: 90%;
}

.subheadline{
    font-size: 20px;
}