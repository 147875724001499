body {
    margin: 0;
    font-family: Georgia, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #c8d2d2;
    background-image: url("/public/assets/haus_3d.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
}

@media (max-width: 1600px) {
    body {
        background-size: 160%;
    }
}
@media (max-width: 1000px) {
    body {
        background-size: 240%;
    }
}
@media (max-width: 500px) {
  body {
      background-size: 300%;
  }
}
