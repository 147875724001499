.navbaritem{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: black;
    margin-top: 0.5em;
    user-select: none;
    width: 100%;
}
.navbaritemheader{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
}
.text {
    width: 100%;
    padding: 8px;
    text-decoration: none;
    font-size: inherit;
    display: block;
    transition: 0.3s;
    cursor: pointer;
    color: black;
    &:hover{
        text-decoration: underline;
    }
}

.expandcollapseicon{
    font-size: 50px;
    padding-left: 0.5em;
    padding-right: 0.5em;
    cursor: pointer;
    transition-duration: 300ms;
    &:hover{
        transform: scale(130%);
    }

    @media (min-width: 2000px) { font-size: 60px;}
    @media (max-width: 1200px) { font-size: 42px;}
    @media (max-width: 800px) { font-size: 38px;}
    @media (max-width: 500px) { font-size: 28px;}
}

.children{
    display: block;
    color: #c9c9c9;
    margin-left: 50px;
    font-size: 24px;
    margin-bottom: 1em;
    background-color: transparent;

    @media (min-width: 2000px) { font-size: 34px;}
    @media (max-width: 1200px) { font-size: 24px; margin-left: 20px;}
    @media (max-width: 800px) { font-size: 20px; }
    @media (max-width: 500px) { font-size: 16px; margin-left: 0;}
}