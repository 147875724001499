.text {
    margin-top: 1em;
    font-size: 18px;
    line-height: 1.4;
    @media (max-width: 800px) { font-size: 14px;}
}

.headline{
    margin-top: 2em;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: 800px) { font-size: 14px; }
}

.largeheadline{
    margin-top: 2em;
    font-size: 22px;
    font-weight: 600;
    @media (max-width: 800px) { font-size: 16px; }
}

.image, .imagevertical{
    margin-top: 1em;
    max-width: 200px;
    max-height: 200px;
    @media (max-width: 800px) { max-width: 120px; max-height: 120px; }
}

.sidebysidetop{
    margin-top: 1em;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    @media (max-width: 800px) { width: 100%; flex-direction: column;  margin-top: 0; align-items: flex-start; }
}

.sidebysidetop2{
    width: 100%;
    display: flex;
    margin-top: 4em;
    margin-left: 4em;
    @media (max-width: 800px) { flex-direction: column; margin: 0; }
    
}

.listtop{
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (max-width: 800px) { width: 100%; }
}

.sidebyside{
    width: 100%;
    justify-content: space-between;
    display: flex;
    @media (max-width: 800px) { flex-direction: column; }
}

.list{
    display: flex;
    flex-direction: column;
    list-style-type: disc;
    gap: 0.7em;
}

.textinputwrapper{
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.sidebysideraw{
    display: flex;
}


.checkbox{
    width: 25px;
    height: 25px;
}

.numberinput {
    width: 100px;
    height: 25px;
    font-size: 18px;
}

.borderwrapper{
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}