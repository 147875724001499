.container {
    position: fixed;
    top: 20px;
    right: 20px;
    display: inline-block;
    cursor: pointer;
    z-index: 2;

  }
  
  .bar1, .bar2, .bar3 {
    width: 40px;
    height: 5px;
    background-color: #000000;
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 4px;
  }
  
  .change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
  }
  
  .change .bar2 {opacity: 0;}
  
  .change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
  }