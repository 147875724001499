.navigationbar{
    position: fixed;
    width: 0%;
    top: 0;
}

.hamburgermenu {
    height: 100%;
    overflow-y: scroll;
    position: fixed;
    z-index: 1;
    width: 0;
    top: 0;
    left: 0;
    background-color: #c8d2d2;
    overflow-x: hidden;
    overflow-y: auto;
    transition: 100ms;
}

.hamburgercontent {
    position: absolute;
    top: 50px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 36px;
    width: 90%;
    margin-left: 2em;
    margin-right: 2em;

    @media (min-width: 2000px) { font-size: 50px;}
    @media (max-width: 1200px) { font-size: 32px;}
    @media (max-width: 800px) { font-size: 28px;}
    @media (max-width: 500px) { font-size: 24px;}
}