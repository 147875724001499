.navigationbar{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    z-index: 1;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #c8d2d2;
}