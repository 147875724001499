.pagefooter{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    @media (max-width: 800px){ margin-left: 0; flex-direction: column; align-items: flex-start; }
    @media (max-width: 500px){ margin-left: 1em; flex-direction: column; align-items: flex-start; }
}

.footeritems{
    margin-right: 1em;
    max-width: 30%;
    @media (max-width: 800px){ max-width: 50%;  margin-top: 2em;}
    @media (max-width: 400px){ max-width: 100%;  margin-top: 2em;}
}