.defaultpage{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.page{
    min-height: calc(100vh - 5em);
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 3em 3em 2em 3em;
    @media (max-width: 1200px) { padding: 1em 1.5em; }
    @media (max-width: 500px) { padding: 0.5em; }
}

.children{
    margin-top: 3em;
    margin-bottom: 5em;
}

